@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --color-primary: 99 26 255;
    --color-secondary: 0 0 0;
    --color-contrast: 255 255 255;

    --primary-color: 0 0 0;
    --primary-color-stop-1: 0 0 0;
    --primary-color-stop-2: 99 26 255;
    --borderWidth: 1px;
    --box-border-color: 255 255 255 / .3;
    --box-background: 0 0 0 / .2;
    --box-radius: 10px;
    --text-color: 255 255 255;
    
    --buttonWidth: 0;
    --button-text-color: 255 255 255;
    --button-text-color-hover: 255 255 255;
    --button-radius: 10px;
    --grid-layout: 1;
    --header-font: "Syne", sans-serif;
}


@font-face {
    font-family: "Syne";
    src: local("Syne"),
        url("./fonts/Syne/syne-bold-webfont.woff2") format("woff2");
    font-weight: normal;
}

body {
    height: 100vh;
    font-family: "Poppins", sans-serif;
}

.app-container {
    min-height: 100vh;
    background-attachment: fixed;
    background-size: cover;
    overflow: auto;
}

.gradient-stop-1 {
    stop-color: var(--gradient-stop-1);
}
  
.gradient-stop-2 {
    stop-color: var(--gradient-stop-2);
}

.app-container > div:not(.flex):not(.fixed) {
    position: relative;
}

.box-container {

}

.box-container.no-fill {
    /* @apply bg-transparent; */
}

.proposals-container[data-gridLayout="true"] {
    --grid-layout: 0;
    --box-background: none;
    --box-border-color: transparent;
    padding: 0;
}

.proposals-container[data-gridLayout="true"] *:not(button) {
    text-align: left;
}

.ant-carousel {
    padding-bottom: 20px;
}
.ant-carousel .slick-dots {
    bottom: -20px;
}

.Aligner {
    display: flex;
    align-items: center;
    min-height: 24em;
    justify-content: center;
}

.Aligner-item {
    flex: 1;
}

.Aligner-item--top {
    align-self: flex-start;
}

.Aligner-item--bottom {
    align-self: flex-end;
}

.Aligner-item--fixed {
    flex: none;
    max-width: 50%;
}

.picker {
    position: relative;
}

.swatch {
    width: 28px;
    height: 28px;
    border-radius: 8px;
    border: 3px solid #fff;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.popover {
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    border-radius: 9px;
    z-index: 10;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.input {
    @apply bg-transparent py-2 px-3 text-white block border-buttonWidth border-white border-opacity-30 rounded-md mt-1 mb-4 h-auto w-full;
}

.btn {
    @apply bg-accent rounded-button border-primary border-buttonWidth border-contrast hover:opacity-80 text-white p-2 px-3;
}

button:disabled, .btn:disabled {
    opacity: .5!important;
    cursor: not-allowed;
}

.markdown {
    --tw-prose-quotes: inherit;
    color: #fffefe;
}

.markdown p {
    line-height: 1.6;
}

.markdown h1, h2, h3, h4 {
    color: var(--color-contrast) !important ;
    font-weight: 300;
}

.markdown strong {
    color: var(--color-contrast) !important ;
    font-weight: 800;
}

.markdown-container {
  min-height: 327px; /* Adjust the minimum height as needed */
}


@layer base {
    h1 {
      @apply text-4xl;
      @apply font-semibold
    }
    h2 {
      @apply text-2xl;
      @apply font-semibold
    }
    h3 {
        @apply text-xl
    }
  }

.bg-accent {
    color: #000;
}
.bg-accent {
    color: #000;
}
