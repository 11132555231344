body {
    --color-primary: 28, 31, 29;
    --color-accent: 143 254 170;
    --border-width: 2px;
    --button-background: 99 26 255;
    --button-background-hover: 99 26 255;
    --button-width: 2px;

    --gradient-stop-1: #8FFEAA;
    --gradient-stop-2: #007AFF;
}


